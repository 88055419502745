
export const PriceData = [
    {
        title: 'On the Run',
        description: 'Exterior Wash',
        descriptionFive: 'Clean Rims & Tires',
        descriptionOne: 'Interior Vaccum & Detailing',
        pricesedans: '$100',
        pricesuv: '$120 - $140'

    },

    {
        title: 'Feel Fresh',
        description: 'Exterior Wash',
        descriptionFive: 'Clean Rims & Tires',
        descriptionOne: 'Interior Vaccum & Scrub Down Panels',
        descriptionTwo: 'Interior Protection & Leather cleaning',
        pricesedans: '$130',
        pricesuv: '$140 - $160'

    },
    {
        title: 'Boss Special',
        description: 'Exterior Wash',
        descriptionFive: 'Clean Rims & Tires',
        descriptionOne: 'Interior Cleaning',
        descriptionTwo: 'Tire Cleaning',
        descriptionThree:'Seat & Carpet Deep Cleaning',
        descriptionFour: 'Ceramic Sealant ',
        descriptionSix: '***PRICES MAY VERY BASED ON CONDITION OF THE VEHICLE*** ',
        pricesedans: '$250',
        pricesuv: '$270 - $340'
    },
]
