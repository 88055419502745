import React, {Component} from 'react';
import { MdCleaningServices } from 'react-icons/md';
import './About.css'


function AboutPage(){
    return(
        <div id="About">

    <div className='AboutContainer'>
        <div className='AboutWrapper'>             

            <div className='RightSide'>
                <div className = "AboutDetail">
                    <h8>Safe and reliable machines that won't scratch your vehicle </h8>
                    <p1> We are an auto detailing service that comes directly
                        to your home to clean any vehicle. We believe you should create
                        a package that best fit your needs at a tailored 
                        price.
                    </p1>
                    <div className ='BotInfo'>
                        <div className ="Feature-MoreInfo">
                          
                            </div>
                        </div> 
                    </div>   
            </div>
        </div>
    </div>
 </div>
    )
}
export default AboutPage
