import React from 'react'
import { PriceData } from "./PriceData";
import './Prices.css';


function PriceElement() {
    return (
        <div className="PriceContainer">
            <div className="PriceWrapper">
                {PriceData.map((item, index) => {
                    return (
                        <div key={index}>
                            <div className="TextPrice">
                                <div className="BodyPrice">
                                    <div className="TitleHeader">
                                        <h3>{item.title}</h3>
                                    </div>
                                    <div className="PriceTitleSedan">

                                    <div className="StartPrice">
                                        <h5> Prices starting at: </h5>
                                        </div>
                                        <div className='VehiclePrice'>
                                        <div className = "Price">
                                        <h5>Sedans:
                                        {item.pricesedans}</h5>
                                        </div> 
                                        <div className="Price">
                                        <h5>SUV-Trucks: 
                                        {item.pricesuv}</h5>
                                        </div>
                                    </div>
                                </div>
                                </div>

                                <div className="description">
                                    <p2>{item.description}</p2>
                                    <p2>{item.descriptionFive}</p2>
                                    <p2>{item.descriptionOne}</p2>
                                    <p2>{item.descriptionTwo}</p2>
                                    <p2>{item.descriptionThree}</p2>
                                    <p2>{item.descriptionFour}</p2>
                                    <p2>{item.descriptionSix}</p2>
                                </div>
                            </div>
                        </div>

                    );
                })}
            </div>
        </div>

    );
}


export default PriceElement
