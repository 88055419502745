import {MdOutlineLocalCarWash } from "react-icons/md";
import {BsTools, BsFillCalendarDateFill} from "react-icons/bs";
import {GrSchedule} from "react-icons/gr";
import {AiFillCreditCard} from "react-icons/ai";
import {RiSteeringFill,RiDiscussLine} from "react-icons/ri";


export const ServiceItem = [
    {
        title: 'Down & Dirty',
        IconType: MdOutlineLocalCarWash,
        description: 'Full Interior, Exterior cleaning, Seat cleaning, Carpet cleaning you name it',

    },

    {
        title: 'Very Mobile',
        IconType: RiSteeringFill,
        description: 'On the go? We make it easy for you by coming to you',

    },
    {
        title: 'Fully Customizable',
        IconType: BsTools,
        description: 'Need something specific? Create a wash to fit your clean',

    },

    {
        title: 'Schedule a Booking',
        IconType: BsFillCalendarDateFill,
        description: 'One less thing to worry about schedule a day and time to make it easy on you',
    },
    {
        title: 'Cash Free Payment',
        IconType: AiFillCreditCard,
        description: 'Pay your way. We accept a variety of different payment to make it easy for you',

    },
]
