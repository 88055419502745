import React from 'react'
import './Footer.css';
import { AiOutlineInstagram } from "react-icons/ai";


function FooterPage() {
    return (
        <div className="FooterContainer">
            <div className="FooterWrapper">
                <div className="FooterBody">
                <div className="Services">
                <h6>Services</h6>
                <p3>We are a auto mobile detailing service that comes straight 
                to your home. Our services provide exterior, interior, and carpet cleaning.
                 Prices vary based on make and model </p3>
                </div>
                </div>

                <div className="FooterBody">
                <div className="Location">
                        <h6>Location</h6>
                        <p3>
                            Our services are provided in the DFW area
                        </p3>
                        <h6>Social Media</h6>
                        <a href="https://www.instagram.com/dirttoshinedallas/">
                        <AiOutlineInstagram size="2.9rem" color="white" cursor="pointer" />
                        </a>
                    </div>

                </div>

                <div className="FooterBody">
                    <div className="Operation">
                        <h6>Contact Us</h6>
                        <p3>Email: dirttoshinedallas@gmail.com</p3>
                            <p3> Phone Number: 972-259-0212</p3>
                           
                    </div>

                </div>
               

            </div>
        </div>

    );
}


export default FooterPage
