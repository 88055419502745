import React, { Component } from "react";
import emailjs from 'emailjs-com';
import './ContactPage.css';
import styled from "styled-components";
import './HomeElement.css'



export const Wrapper = styled.div`
 
`


export const ContactPageHeader = styled.div`
  display:flex;
  justify-content:center;
  align-items:center;
  width:100%;
  

`
export const Header = styled.div`
  display:flex;
  justify-content: center;
  align-items:center;
  font-size: clamp(2rem, 2.5vw, 2rem); 
  font-family: haboro-contrast-extended,sans-serif;
  font-weight: 600;
  font-style: normal;
  color: white;
  padding-bottom: 1rem;
  font-family: 'Anton', sans-serif;

  color:black;
  `

export const input = styled.input`
     
`;
const regularExpression = RegExp(/^[a-zA-Z0-9]+@[a-zA-Z0-9]+\.[A-Za-z]+$/)

const EMAILJS_SERVICEID = `${process.env.REACT_APP_EMAILJS_SERVICEID}`
const EMAILJS_TEMPLATEID = `${process.env.REACT_APP_EMAILJS_TEMPLATEID}`
const EMAILJS_USERID = `${process.env.REACT_APP_EMAILJS_USERID}`

const validation = ({ error, ...rest }) => {
  let checkValidation = false;

  Object.values(error).forEach(val => {
    if (val.length > 0) {
      checkValidation = false
    } else {
      checkValidation = true
    }
  });

  Object.values(rest).forEach(val => {
    if (val === null) {
      checkValidation = false
    } else {
      checkValidation = true
    }
  });

  return checkValidation;
};

export default class HomeElement extends Component {

  constructor(props) {
    super(props)

    this.state = {
      first: '',
      last: '',
      email: '',
      number: '',
      car: '',
      package: '',
      address: '',
      ad: '',
      error: {
        first: '',
        last: '',
        email: '',
        number: '',
        car: '',
        package: '',
        address: '',
        ad: '',


      }
    }
    this.onFormSubmit = this.onFormSubmit.bind(this);

  }

  onFormSubmit = event => {

    event.preventDefault();

    emailjs.sendForm(
      EMAILJS_SERVICEID,
      EMAILJS_TEMPLATEID,
      event.target,
      EMAILJS_USERID)


    if (validation(this.state)) {
      <div>Your message was sent successfully</div>

      console.log(this.state)
    } else {
      console.log("Error occured");
    }
    event.target.reset();
  };


  formObject = event => {

    event.preventDefault();

    const { name, value } = event.target;
    let error = { ...this.state.error };

    switch (name) {
      case "first":
        error.first = value.length < 2 ? "Enter your First Name" : "";
        break;
        case "last":
          error.last = value.length < 5 ? "Enter your Last Name" : "";
          break;
      case "email":
        error.email = regularExpression.test(value)
          ? ""
          : "Email is not valid";
        break;
      case "number":
        error.number =
          value.length < 10 ? "Should be 10 digits" : "";
        break;
      case "address":
        error.address =
          value.length < 2 ? "Email should 5 characaters long" : "";
        break;
        case "car":
          error.car =
            value.length < 2 ? "Car should 2 characaters" : "";
          break;
        case "ad":
          error.ad =
            value.length < 2 ? "How did you hear about us?" : "";
          break;
        case "package":
        error.package =
          value.length < 2 ? "Select a package" : "";
        break;
      default:
        break;
    }

    this.setState({
      error,
      [name]: value
    })
  };

  render() {

    const { error } = this.state;

    return (
      <div id="HomeElement">
        <div className="Container">
          <div className="Wrapper">
            <div className="HeaderSection">
              <div className="RightSide">
                <h1>Dirt to Shine</h1>
                <h7>Dallas Mobile Car Wash</h7>
              </div>
              <div className="LeftSide">
                <div className="Section">
                  <Wrapper>
                    <ContactPageHeader>
                      <Header>Request a Inquiry</Header>
                    </ContactPageHeader>
                  </Wrapper>
                  <form className="contact-form" onSubmit={this.onFormSubmit}>
                    <div className="ContactContainer">
                      <div className="contact-box">
                        <div className="input-row">
                          <div className="input-group">
                            <label>FirstName *</label>
                            <input type="text" name="first" placeholder="Mary" required="required"
                              onChange={this.formObject}
                              className={error.first.length > 5 ? "is-invalid form-control" : "form-control"} />

                            {error.first.length > 0 && (
                              <span className="invalid-feedback">{error.first}</span>
                            )}
                          </div>

                          <div className="input-group">
                            <label>LastName *</label>
                            <input type="text" name="last" placeholder="Jane" required="required"
                              onChange={this.formObject}
                              className={error.last.length > 2 ? "is-invalid form-control" : "form-control"} />

                            {error.last.length > 0 && (
                              <span className="invalid-feedback">{error.last}</span>
                            )}
                          </div>


                        </div>
                        <div className="input-row">
                          <div className="input-group">
                            <label>E-Mail Address *</label>
                            <input type="text" name="email" placeholder="Me@email.com" required
                          
                              className={error.email.length > 0 ? "is-invalid form-control" : "form-control"}
                              onChange={this.formObject} />

                            {error.email.length > 0 && (
                              <span className="invalid-feedback">{error.email}</span>
                            )}
                          </div>



                          <div className="input-group">
                            <label>Phone number *</label>
                            <input type="number" name="number" placeholder="xxx-xxx-xxxx" required
                              className={error.number.length > 10 ? "is-invalid form-control" : "form-control"}
                              onChange={this.formObject} />

                            {error.number.length > 0 && (
                              <span className="invalid-feedback">{error.number}</span>
                            )}

                          </div>
                        </div>


                        <div className="input-row">
                          <div className="input-group">
                            <label>Package *</label>
                              <select name="package"
                              id="inputService" size="1">
                              <option value="OnTheRun">On The Run</option>
                              <option value="FreshFeel">Fresh Feel</option>
                              <option value="BossSpecial">Boss Special</option>
                              </select>
                          </div>

                          <div className="input-group">

                            <label>Make & Model *</label>
                            <input type="text" name="car" required
                              className={error.car.length > 2 ? "is-invalid form-control" : "form-control"}
                              onChange={this.formObject} />

                            {error.car.length > 0 && (
                              <span className="invalid-feedback">{error.car}</span>
                            )}
                          </div>

                        </div>

                        <div className="input-group">
                            <label>How did you hear about us? *</label>
                              <select name="ads"
                              id="inputService" size="1">
                              <option value="google">Google Search</option>
                              <option value="Facebook">Facebook </option>
                              <option value="Instagram">Instagram</option>
                              <option value="NextDoor">NextDoor</option>
                              <option value="Referral">Referral</option>
                              </select>
                        </div>

                        <div className="input-group">

                          <label>Address *</label>
                          <input type="text" name="address" placeholder="1234 Mary Ln Dallas, TX 98765" required
                            className={error.address.length > 20 ? "is-invalid form-control" : "form-control"}
                            onChange={this.formObject} />

                          {error.address.length > 0 && (
                            <span className="invalid-feedback">{error.address}</span>
                          )}
                        </div>
                      </div>
                      <div class="wrapper">
                        <button class="fade">Submit</button>
                      </div>


                    </div>
                  </form>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}