import React from 'react'
import {ServiceItem} from "./ServiceItem";
import './Services.css';

//https://colorswall.com/palette/132272
function Services () {


        return (
            <div className="ServiceContainer">
                <div className= "ServiceWrapper">
                    {ServiceItem.map((item, index) => {
                        return(
                            <div key={index}>
                                <div className = "TextService">
                                    <div className = "BodyService">
                                <item.IconType size="2.9rem" color="#e44da0"/>
                                <h2>{item.title}</h2>
                                <p8>{item.description}</p8>
                                </div>
                                </div>
                            </div>

                        );
                    })}
                    </div>
            </div>

        );
    }


export default Services